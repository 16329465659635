import React from "react"
import styled from "styled-components"

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 12%;
  margin: 62px 0;

  @media (max-width: 1023px) {
    margin: 42px 0 22px;
    padding: 0 5%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;

  p {
    margin: 16px 0;
  }

  ul {
    padding: 20px 0 20px 40px;
  }

  a {
    color: var(--warm-orange);
  }
  a:hover {
    color: var(--cool-black);
  }
`

export default function ContentSection1() {
  return (
    <ContentContainer>
      <ContentWrapper>
        <h2>
          At Ripplz, we are committed to protecting and respecting the privacy
          of our clients and website users.
        </h2>
        <p>
          This policy outlines how and why we collect the personal information
          of these subjects, what we do with this information and how we keep it
          secure. We are proactive in ensuring that our website users’
          information is protected to provide a safe and secure user experience.
          This website complies with all UK national laws and requirements
          regarding user privacy.
        </p>
        <h3>Topics covered in this policy:</h3>
        <ul>
          <li>Your information</li>
          <li>What information we collect</li>
          <li>What we do with the information we collect</li>
          <li>Use of cookies</li>
          <li>Other websites</li>
          <li>Access to your information</li>
          <li>Contact us</li>
        </ul>
        <p>
          By using this website, you consent to the processing of your data for
          the purposes stated in the policy below. If you do not accept these
          terms, please exit the website. This policy is reviewed regularly and
          may be subject to change.
        </p>
        <h3>What Information We Collect About You</h3>
        <p>
          The information we collect about you at Ripplz includes but is not
          limited to; your name, contact details and relevant information you
          have provided. No irrelevant or discriminative data will be stored.
          The personal information we collect about you is kept on our protected
          CRM database and is kept secure until it is no longer required. We
          collect this data from information sent straight from users of this
          website.
        </p>
        <h3>What We Do with Your Information</h3>
        <p>
          We use your data to perform the services requested. For example, if
          you fill out a “Contact Me” Web form, we will use the information
          provided to contact you about your interest in the Services. We may
          also use Data about Ripplz customers for marketing purposes. For
          example, we may use the information you provide to contact you to
          further discuss your interest in the Services and to send you
          information regarding the Company, its affiliates, and its partners,
          such as information about promotions or events. We may also receive
          information about Customers from other sources, including third
          parties from whom we have purchased data, and combine this information
          with Data we already have about you. This helps us to update, expand
          and analyse our records, identify new customers, and create more
          tailored advertising to provide products and services that may be of
          interest to you. We may also use Web Site Navigational Information
          alone or in combination with Data Ripplz customers to provide
          personalised information about the company. This information is not
          shared with or sold to any 3rd parties.
        </p>
        <h3>Use of cookies</h3>
        <p>
          To provide the best possible experience for our users, this website
          uses cookies. A cookie is a small amount of data generated by a
          website and saved by your web browser. Its purpose is to remember
          information about you. This website uses a cookie control system which
          complies with EU and Australian cookie legislation which requires
          websites to get consent from visitors to store or retrieve any
          information. These pieces of information are used by us to improve the
          functionality of the website, providing a better experience for users.
          <br />
          <br />
          Users are advised that they are able to control their cookies through
          their browser settings. Find out more at{" "}
          <a
            href="https://aboutcookies.org/"
            alt="a guide to controlling cookies"
          >
            AboutCookies.org
          </a>
          .
        </p>
        <h3>Other Websites</h3>
        <p>
          This website may occasionally hyperlink to other websites. By clicking
          on a hyperlink, you will be leaving the Ripplz website, and the use of
          those websites will not be covered by the Ripplz Privacy Policy. Users
          are advised to check the Privacy Policy of those websites to find out
          how they deal with your data.
        </p>
        <p>
          Ripplz utilise a multitude of social media platforms. Users are
          advised that the terms of use on those websites are custom to the
          Terms and Conditions and Privacy Policies of the platform.
        </p>
        <h3>Access to Your Information</h3>
        <p>
          You are able to request a copy of the information we hold about you.
          If you wish to see this information, please contact us using the
          information provided below in the “How to Contact Us” section of this
          policy. Users can request that their information is deleted or
          withdraw their consent.
        </p>
        <p>
          We want the information we hold about you to be as accurate as
          possible. Please don’t hesitate to contact to tell us of any updates
          to your information.
        </p>
        <h3>How to Contact Us</h3>
        <p>
          Questions regarding this policy are welcomed and should be addressed
          to:
        </p>
        <p style={{ fontWeight: "500" }}>
          UK Office <br />
          Airedale House <br />
          423 Kirkstall Road <br />
          Leeds <br />
          West Yorkshire <br />
          LS4 2EW
          <br />
          <br />
          Tel: <br />
          <a href="tel:0113 3572075">0113 3572075</a>
          <br />
          <a href="tel:0113 3572076">0113 3572076</a>
          <br />
          Contact: <br />
          <a href="mailto:info@ripplz.co.uk">info@ripplz.co.uk</a>
          <br />
        </p>
      </ContentWrapper>
    </ContentContainer>
  )
}
