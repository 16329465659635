import React from "react"
import HeaderSection from "../components/Privacy/ContentHeader"
import Layout from "../components/HeaderFooter/Layout"
import ContentSection1 from "../components/Privacy/ContentSection1"
import { Helmet } from "react-helmet"

export default function privacy() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ripplz - Privacy Policy</title>
        <meta
          name="description"
          content="We care about privacy, so we are proactive in ensuring that our website users’ information is protected to provide a safe and secure user experience."
        />
      </Helmet>
      <HeaderSection />
      <ContentSection1 />
    </Layout>
  )
}
